$bg-color: #fff;
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,500;0,600;0,800;1,500&family=Rowdies:wght@300&display=swap");

body {
  margin: 0;
  color: #000 !important;
  font-family: normal normal normal 14px/13px Lato !important;
}

a {
  text-decoration: none !important;
}

div {
  font-family: normal normal normal 14px/13px Lato !important;
}

.b-btn {
  background-color: #000;
  color: #fff;
  transition: 0.4s;
  border: 2px solid black;

  &:hover {
    background-color: #fff !important;
    color: #000;
  }
}

.earn-btn {
  background-color: #fff;
  color: #000;
  transition: 0.4s;
  border: 2px solid #fff;

  &:hover {
    background-color: #000 !important;
    color: #fff;
  }
}

header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: $bg-color;

  a {
    font-family: normal normal bold 24px/65px Lato;
    height: 70px;

    &:hover {
      text-decoration: none !important;
    }
  }

  .navlink {
    color: rgba(0, 0, 0, 0.75);
  }
}

#logo {
  width: auto;
}

.menu-items {
  position: fixed;
  right: 0%;
  transform-origin: right top;
  transform: scale(0);
  width: 50%;
  transition: 0.2s linear;
  z-index: 1;
  color: #000;
  background-color: #fff;
  border-left: 1px solid rgba(0, 0, 0, 0.212);
  border-bottom: 1px solid rgba(0, 0, 0, 0.212);

  div {
    border-bottom: 1px solid rgba(0, 0, 0, 0.212);
    text-align: center;
    margin: 20px;

    a {
      width: 100%;
      padding: 5px;
    }
  }
}

.active {
  right: 0;
  transform: scale(1);
}

.heading {
  line-height: 1 !important;
  font-size: clamp(2rem, calc(3.5vw + 5px), 4rem) !important;
}

.hero {
  background-color: $bg-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hero-img {
  position: relative;
  left: -70px;
}

.aboutCard {
  height: 350px;
}

.serviceCard {
  height: 280px;
}

.about {
  background-color: $bg-color;
  overflow-x: hidden;
}

.services {
  background-color: $bg-color;
  overflow-x: hidden;
}

.earn {
  background: #000;
  overflow-x: hidden;
}

.cloud {
  width: 100%;
  margin-bottom: -10px;

  img {
    width: 100%;
  }
}

footer {
  color: #131313;
  position: relative;
  background: $bg-color;
  overflow-x: hidden;

  .address {
    color: #131313;
    list-style: none;
  }

  a {
    padding: 5px 10px;
    color: #131313 !important;
    text-decoration: none !important;

    &:hover {
      text-decoration: underline !important;
    }
  }

  img {
    border-radius: 50%;
    margin-bottom: 10px;
  }

  ul {
    list-style: none;
  }

  .copyright {
    font-family: normal normal normal 18px/35px Josefin Sans !important;
  }
}

.scroller {
  position: fixed;
  z-index: 1;
  bottom: 10px;
  right: 10px;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  background: #000;
  color: white;
  text-align: center;

  #scroller {
    width: 40px;
    border-radius: 100%;
    padding: 3px;
  }
}

@media screen and (max-width: 767px) {
  .about {
    img {
      transform: rotate(0deg);
    }
  }

  .earn {
    #phone {
      transform: rotate(0);
    }
  }

  .hero {
    justify-content: flex-start;
  }

  .hero-img {
    left: 0;
  }


  header {
    a {
      height: 55px;
    }
  }

  #logo {
    height: 30px;
  }

  .aboutCard {
    height: 350px;
  }

  .serviceCard {
    height: 220px;
  }

  .quick-texts {
    font-size: 15px !important;
  }
}

@media screen and (max-width: 1205px) {
  .services {
    ul {
      width: 100%;
    }
  }
}

@media screen and (max-width: 400px) {
  .quick-texts {
    font-size: 12px !important;
  }
}